export default {
  methods: {
    goBack() {
      if (this.$router.length <= 1) {
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    backToNative() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === 'fb-flutter-Webview') {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage')
        }
      } else if (ua === iOSUA) {
        try {
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else {
        this.$router.back()
      }
    },
  },
}
