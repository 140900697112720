// eslint-disable-next-line camelcase
const mime_types = {
  '.gif': 'IMAGE',
  '.jpeg': 'IMAGE',
  '.jpg': 'IMAGE',
  '.png': 'IMAGE',
  '.bmp': 'IMAGE',
  '.webp': 'IMAGE',
  '.psd': 'IMAGE',
  '.svg': 'IMAGE',
  '.tiff': 'IMAGE',
  '.docx': 'WORD',
  '.doc': 'WORD',
  '.pdf': 'PDF',
  '.xls': 'EXCEL',
  '.xlsx': 'EXCEL',
  '.mp4': 'VIDEO',
  '.mkv': 'VIDEO',
  '.flv': 'VIDEO',
  '.avi': 'VIDEO',
  '.mpg': 'VIDEO',
  '.mpeg': 'VIDEO',
  '.wmv': 'VIDEO',
  '.mov': 'VIDEO',
  '.3gp': 'VIDEO',
  '.3gpp': 'VIDEO',
  '.mp3': 'AUDIO',
  '.ogg': 'AUDIO',
  '.oga': 'AUDIO',
  '.m4a': 'AUDIO',
  '.aac': 'AUDIO'
}
export function gettype(path) {
  const index = path.slice(path.lastIndexOf('.'))
  return mime_types[index.toLowerCase()] || false
}
