<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backList(0)"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
    </fb-header>
    <!-- 日期组件 -->
    <van-calendar
      v-model="stageVisible"
      @confirm="selectStage"
      :default-date="stageDate"
    />
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list :caseId="caseId" @select="handleCaseListSelect" />
    </van-popup>
    <!-- 支付方式 -->
    <van-popup v-model="payTypeListVisible" position="top" get-container="body">
      <stamp-type
        :options="payTypeList"
        :radio="payTypeSelect"
        title="支付方式"
        @select="handlePayTypeListSelect"
      />
    </van-popup>
    <!-- 收支类型 -->
    <van-popup v-model="itemListVisible" position="top" get-container="body">
      <stamp-type
        :options="itemList"
        :radio="categorySelect"
        title="收支类型"
        @select="handleItemListSelect"
      />
    </van-popup>
    <!-- 收款人 -->
    <van-number-keyboard
      :show="show"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="show = false"
      @input="onInput"
      @delete="onDelete"
      @hide="hideKeyboard"
    />
    <van-popup
      v-model="payeeListVisible"
      position="bottom"
      get-container="body"
    >
      <stamp-type
        :options="payeeList"
        :radio="payeeSelect"
        title="收款人"
        @select="handlePayeeListSelect"
      />
    </van-popup>
    <div class="oa-add-receive">
      <div class="oa-add-receive-case" @click="switchCaseList">
        <div v-if="caseName === ''" style="color:#999">请选择案件</div>
        <div v-else>{{ caseName }}</div>
      </div>
      <div class="oa-add-receive-content">
        <div class="oa-case-price" v-if="caseName !== ''">
          <div><span>应收：</span>{{ price.receivable }}</div>
          <div><span>实收：</span>{{ price.received }}</div>
          <div style="color:red">
            <span>未收：</span>{{ price.uncollected }}
          </div>
        </div>
        <div class="oa-receive-list" v-if="caseName !== ''">
          <div class="oa-receive" v-for="(item, i) in receiveList" :key="i">
            <div class="oa-receive-title">
              <div>收支{{ i + 1 }}</div>
              <!-- <div class="oa-receive-title-btn" @click="save(item, i)">
                <div>
                  保存
                </div>
              </div> -->
              <img
                @click="deleteReceive(item, i)"
                src="../../../../../public/img/icons/icon_delete.png"
                alt=""
              />
            </div>
            <div class="oa-receive-content">
              <div class="oa-receive-content-box">
                <div
                  class="oa-receive-content-box-time"
                  style="color:#0079FE"
                  @click="switchStage(item, i)"
                >
                  {{ item.stage }}
                </div>
                <div style="margin-left:10px" @click="switchItemList(item, i)">
                  <span v-if="item.typeText === ''" style="color:#CCCCCC"
                    >请选择收支类型</span
                  >
                  <span v-else>{{ item.typeText }}</span>
                  <van-icon name="arrow-down" color="#CCCCCC" size="14px" />
                </div>
                <div class="oa-receive-content-box-price">
                  <van-field
                    v-model="item.amount"
                    placeholder="请输入"
                    input-align="right"
                    type="number"
                    readonly
                    clickable
                    @touchstart.stop="onKeyNumber(item, i)"
                    @blur="amountBlur(item, i)"
                  />
                  <span><span>元</span><span style="color:red">*</span></span>
                </div>
              </div>
              <div class="oa-receive-content-box">
                <div @click="switchPayTypeList(item, i)">
                  <span v-if="item.payTypeText === ''" style="color:#CCCCCC"
                    >支付方式</span
                  >
                  <span v-else>{{ item.payTypeText }}</span>
                  <van-icon name="arrow-down" size="18px" />
                </div>
                <div>
                  <van-field
                    v-model="item.account"
                    placeholder="请输入支付账号"
                    @blur="accountBlur(item, i)"
                    input-align="right"
                  />
                </div>
              </div>
              <div class="oa-receive-content-box">
                <div>付款人</div>
                <div style="padding:10px 0;">
                  <van-field
                    v-model="item.paymenter"
                    placeholder="请输入付款人"
                    @blur="paymenterBlur(item, i)"
                    input-align="right"
                  />
                </div>
              </div>
              <div
                class="oa-receive-content-box"
                @click="switchPayeeList(item, i)"
              >
                <div>收款人</div>
                <div>
                  <span
                    v-if="item.payee === '' || item.payee === null"
                    style="color:#CCCCCC"
                    >请选择收款人</span
                  >
                  <span v-else>{{ item.payee }}</span>
                  <van-icon name="arrow-down" color="#CCCCCC" size="14px" />
                </div>
              </div>
              <div class="oa-receive-content-box">
                <div>备注</div>
                <div>
                  <van-field
                    v-model="item.remarks"
                    placeholder="请输入内容"
                    input-align="left"
                    @blur="remarksBlur(item, i)"
                  />
                </div>
                <img
                  @click="addImg(item, i)"
                  src="../../../../../public/img/icons/u4647-1.png"
                  alt=""
                />
              </div>
              <div
                class="oa-receive-content-box"
                v-if="item.collections.length > 0"
              >
                <div class="oa-receive-content-box-resource">
                  <div
                    class="resource-image-box"
                    v-for="(items, index) in item.collections"
                    :key="index"
                  >
                    <i
                      v-if="items.type === 'WORD'"
                      class="iconfont icon-word"
                      style="color: rgb(108, 168, 255);"
                    ></i>
                    <i
                      v-if="items.type === 'EXCEL'"
                      class="iconfont icon-exl"
                      style="color: rgb(139, 221, 108);"
                    ></i>
                    <i
                      v-if="items.type === 'PDF'"
                      class="iconfont icon-PDF"
                      style="color: rgb(62, 179, 240);"
                    ></i>
                    <i
                      v-if="items.type === 'VIDEO'"
                      class="iconfont icon-shipin"
                      style="color: rgb(75, 158, 251);"
                    ></i>
                    <i
                      v-if="items.type === 'AUDIO'"
                      class="iconfont icon-shipin"
                      style="color: rgb(75, 158, 251);"
                    ></i>
                    <img
                      v-if="items.type === 'IMAGE'"
                      :src="items.content"
                      alt=""
                      class="resource-image-img"
                    />
                    <img
                      src="../../../../../public/img/icons/icon_delete.png"
                      alt=""
                      class="resource-image-delete"
                      @click="deleteImg(i, index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="oa-receive-add" @click="addReceive" v-if="caseName !== ''">
          <i class="iconfont icon-add"></i>
          <div>添加</div>
        </div>
      </div>
      <!-- <div class="oa-button-view">
        <button @click="sure">确定</button>
      </div> -->
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import CaseList from '../../components/caseList'
import StampType from '../../components/stampType'
import { mapMutations, mapState } from 'vuex'
import { gettype } from '../../common/fileType'

export default {
  name: 'oa-add-receive',
  mixins: [goBackMixin],
  components: {
    CaseList,
    StampType
  },
  data() {
    return {
      show: false,
      title: '收支',
      caseListVisible: false, // 案件列表弹框
      caseId: 0, // 案件id
      caseName: '', // 案件名称
      isAdd: 0, // 0新增  1修改
      price: {}, // 费用
      receiveList: [], // 收支列表
      receiveIndex: 0,
      payTypeListVisible: false, // 支付方式弹框
      payTypeSelect: '',
      payTypeList: [
        {
          value: 'ALI',
          text: '支付宝'
        },
        {
          value: 'WECHAT',
          text: '微信'
        },
        {
          value: 'CASH',
          text: '现金'
        },
        {
          value: 'POS',
          text: 'POS机'
        }
      ], // 支付方式列表
      itemListVisible: false, // 收支类型弹框
      itemList: [], // 收支类型列表
      categorySelect: '', // 收支类型
      stageVisible: false, // 时间弹框
      stageDate: new Date(),
      payeeListVisible: false, // 收款人弹框
      payeeList: [], // 收款人列表
      payeeSelect: ''
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapMutations('oa', ['setOaTab']),
    onKeyNumber(item, i = -1) {
      this.show = true
      if (i !== -1) {
        this.receiveIndex = i
      }
    },
    hideKeyboard() {
      this.save(this.receiveList[this.receiveIndex], this.receiveIndex)
    },
    onDelete(value) {
      const receiveList = this.receiveList
      var money = receiveList[this.receiveIndex].amount.toString()
      receiveList[this.receiveIndex].amount = money.substring(
        0,
        money.length - 1
      )
      this.receiveList = receiveList
    },
    onInput(value) {
      console.log(value)
      const receiveList = this.receiveList
      receiveList[this.receiveIndex].amount =
        receiveList[this.receiveIndex].amount + '' + value
      this.receiveList = receiveList
    },
    // 项目名称点击事件
    switchCaseList() {
      if (this.isAdd === 0) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      console.log(options)
      this.caseNo = options.caseNo
      this.caseName = options.label
      this.caseId = options.caseId
      // 根据案件id获取案件信息
      this.getCaseInfo(options.caseId)
      this.switchCaseList()
    },

    // 根据案件id获取案件信息
    getCaseInfo(caseId) {
      this.$axios
        .get(`${this.$base}/management/legal/case/${caseId}/finance`)
        .then(res => {
          this.price = res.data
          this.caseName = res.data.legalCase.caseName
          this.caseNo = res.data.legalCase.caseNo
          this.legalCase = res.data.legalCase
          this.$axios
            .get(
              `${this.$base}/management/legal/case/${caseId}/finance/records`
            )
            .then(res => {
              if (res.data.data && res.data.data.content.length > 0) {
                for (let i = 0; i < res.data.data.content.length; i++) {
                  res.data.data.content[i].payTypeText = ''
                  this.payTypeList.map(item => {
                    if (item.value === res.data.data.content[i].mode) {
                      res.data.data.content[i].payTypeText = item.text
                    }
                  })
                  res.data.data.content[i].typeText = ''
                  this.itemList.map(item => {
                    if (item.value === res.data.data.content[i].category.name) {
                      res.data.data.content[i].typeText = item.value
                    }
                  })
                  res.data.data.content[i].stage = this.timestampToTime(
                    res.data.data.content[i].stage
                  )
                  res.data.data.content[i].collections = []
                }
                this.receiveList = res.data.data.content
                console.log('this.receiveList', this.receiveList)
              } else {
                this.addReceive()
              }
              this.$toast.clear()
            })
        })
    },

    // 点击添加事件
    addReceive() {
      const that = this
      const object = {
        stage: new Date().getTime(),
        typeText: this.itemList[0].text,
        category: this.categoryList[0],
        amount: '',
        mode: null,
        payTypeText: '',
        account: '',
        paymenter: this.legalCase.attacker,
        remarks: '',
        id: '',
        flag: true,
        legalCaseId: this.caseId,
        legalCaseNumber: this.caseNo,
        collections: [],
        isAdd: true,
        payee: this.legalCase.agents
      }
      object.stage = that.timestampToTime(object.stage)
      this.receiveList.push(object)
      // this.$axios
      //   .post(
      //     `${this.$base}/management/legal/case/${this.caseId}/finance/record`,
      //     object
      //   )
      //   .then(res => {
      //     if (res.data.code === 200) {
      //       object.stage = that.timestampToTime(object.stage)
      //       object.id = res.data.data.id
      //       this.receiveList.push(object)
      //     }
      //   })
    },

    // 删除图片
    deleteImg(i, index) {
      this.receiveList[i].collections.splice(index, 1)
      this.save(this.receiveList[i])
    },

    // 点击添加图片
    addImg(item, i) {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        this.receiveIndex = i
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        // ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          const f = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            f,
            {
              next: file => {},
              complete: (res, file) => {},
              error: () => {
                this.$toast('上传失败，请重新选择')
              }
            },
            (fileopt, key, file) => {
              const url = qiniuBase + '/' + key
              if (!gettype(fileopt.name)) {
                return this.$notify('文件格式不支持')
              }
              var obj = {
                name: fileopt.name,
                type: gettype(fileopt.name),
                content: url,
                size: fileopt.size
              }
              item.collections.push(obj)
              this.save(item, i)
              document.getElementById('file').remove()
            }
          )
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log('收支的原生端回调===>')
      console.log(data)
      const list = JSON.parse(data)
      list.map(item => {
        if (!gettype(item.name)) {
          return this.$notify('文件格式不支持')
        }
        var obj = {
          name: item.name,
          type: gettype(item.name),
          content: item.url,
          size: item.size
        }

        this.receiveList[this.receiveIndex].collections.push(obj)
        this.save(this.receiveList[this.receiveIndex], this.receiveIndex)
      })
    },

    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(parseInt(cjsj)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // var h =
      //   ' ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      // var m =
      //   date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D
    },

    // 支付方式点击事件
    switchPayTypeList(item, i = -1) {
      this.payTypeListVisible = !this.payTypeListVisible
      if (i !== -1) {
        this.payTypeSelect = item.mode
        this.receiveIndex = i
      }
    },
    handlePayTypeListSelect(options) {
      this.receiveList[this.receiveIndex].mode = options.value
      this.receiveList[this.receiveIndex].payTypeText = options.text
      this.save(this.receiveList[this.receiveIndex])
      this.switchPayTypeList()
    },

    // 收款人点击事件
    switchPayeeList(item, i = -1) {
      this.payeeListVisible = !this.payeeListVisible
      if (i !== -1) {
        this.payeeSelect = item.payee
        this.receiveIndex = i
      }
    },
    handlePayeeListSelect(options) {
      this.receiveList[this.receiveIndex].payee = options.value
      this.save(this.receiveList[this.receiveIndex])
      this.switchPayeeList()
    },

    // 获取收支类型
    getItme() {
      this.$axios
        .get(`${this.$base}/management/finance/category?divisible=true`)
        .then(res => {
          this.categoryList = res.data.data
          res.data.data.forEach((item, index) => {
            this.itemList.push({
              value: item.name,
              text: item.name
            })
          })
        })
    },

    // 收支类型点击事件
    switchItemList(item, i = -1) {
      this.itemListVisible = !this.itemListVisible
      if (i !== -1) {
        this.categorySelect = item.typeText
        this.receiveIndex = i
      }
    },
    handleItemListSelect(options) {
      this.receiveList[this.receiveIndex].typeText = options.value
      this.save(this.receiveList[this.receiveIndex])
      this.switchItemList()
    },

    // 日期点击事件
    switchStage(item, i = -1) {
      this.stageVisible = !this.stageVisible
      if (i !== -1) {
        this.stageDate = new Date(item.stage)
        this.receiveIndex = i
      }
    },
    selectStage(date) {
      console.log(date)
      this.receiveList[this.receiveIndex].stage = this.timestampToTime(
        date.getTime()
      )
      this.save(this.receiveList[this.receiveIndex])
      this.switchStage()
    },

    // 获取收款人
    getPayee(limit) {
      this.$axios
        .get(`${this.$base}/lts/user/queryColleague`, {
          params: { limit: limit, pageNum: 1 }
        })
        .then(res => {
          console.log(res)
          if (res.data.data && res.data.data.items) {
            this.payeeList = []
            res.data.data.items.map(item => {
              this.payeeList.push({
                text: item.lawyerName,
                value: item.lawyerName
              })
            })
            this.payeeList.unshift({
              text: this.userInfo.nickName,
              value: this.userInfo.nickName
            })
            if (res.data.data.total > limit) {
              this.getPayee(res.data.data.total)
            }
          }
        })
    },

    // 点击删除事件
    deleteReceive(item, i) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          if (item.id !== '') {
            const response = await this.$axios.post(
              `${this.$base}/management/legal/case/${
                this.caseId
              }/finance/record/delete/${item.id}`
            )
            const { code } = response.data
            if (code === 200) {
              this.$toast.success('删除成功')
              this.receiveList.splice(i, 1)
            }
          } else {
            this.receiveList.splice(i, 1)
          }
        })
        .catch(() => {
          // on cancel
        })
    },

    //  备注失去焦点
    remarksBlur(item, i) {
      this.save(this.receiveList[i])
    },

    //  付款人失去焦点
    paymenterBlur(item, i) {
      this.save(this.receiveList[i])
    },

    //  付款人账号失去焦点
    accountBlur(item, i) {
      this.save(this.receiveList[i])
    },

    //
    amountBlur(item, i) {
      this.save(this.receiveList[i])
    },

    save(item, i) {
      var that = this
      if (item.isAdd) {
        if (item.typeText === '') {
          // return this.$notify('请选择收支类型')
          return
        }
        if (item.amount === '') {
          // return this.$notify('请输入金额')
          return
        }
        var tempStage = new Date(item.stage).getTime()
        this.$axios
          .post(
            `${this.$base}/management/legal/case/${this.caseId}/finance/record`,
            {
              ...item,
              stage: tempStage
            }
          )
          .then(res => {
            if (res.data.code === 200) {
              item.stage = that.timestampToTime(tempStage)
              item.id = res.data.data.id
              item.isAdd = false
            } else {
              this.$notify('保存失败')
            }
          })
          .catch(err => {
            console.log(err)
            this.$notify('保存失败')
          })
      } else {
        this.updateReceive(item, i)
      }
    },

    // update
    updateReceive(item, i) {
      console.log(item)
      if (item.typeText === '') {
        // return this.$notify('请选择收支类型')
        return
      }
      if (item.amount === '') {
        // return this.$notify('请输入金额')
        return
      }
      this.categoryList.map(items => {
        if (items.name === item.typeText) {
          item.category = items
        }
      })
      var tempTime = item.stage
      // item.stage = new Date(item.stage).getTime()
      item.legalCaseNumber = this.caseNo
      this.$axios
        .put(
          `${this.$base}/management/legal/case/${this.caseId}/finance/record/${
            item.id
          }`,
          {
            ...item,
            stage: new Date(item.stage).getTime()
          }
        )
        .then(res => {
          if (res.data.code === 200) {
            item.stage = tempTime
          } else {
            this.$notify('保存失败')
          }
        })
        .catch(err => {
          console.log(err)
          this.$notify('保存失败')
        })
    },

    // 返回列表页面
    backList(time = 1000) {
      console.log(this.directGoNative)
      console.log(window.nativeEnterIndex)
      console.log(this.$router.length)
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.setOaTab('1')
        setTimeout(() => {
          this.$router.go(-1) // 返回上一层
        }, time)
      }
    },

    // 点击确定
    sure() {
      this.backList(0)
    }
  },
  created() {
    this.getItme()
    this.getPayee(20)
    this.isAdd = parseInt(this.$route.query.isAdd)
    if (this.isAdd === 1) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.caseId = this.$route.query.receive
      this.getCaseInfo(this.$route.query.receive)
    } else {
      this.switchCaseList()
    }
  },
  mounted() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  }
}
</script>

<style lang="stylus">
.oa-add-receive
  position relative
  padding 0 20px 100px 20px
  box-sizing border-box
  & .oa-add-receive-case
    padding 10px 5px
    box-sizing border-box
    font-size 16px
    border-bottom 1px solid #CCC
    div
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 1
      overflow hidden
  & .oa-add-receive-content
    & .oa-case-price
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 5px 5px
      box-sizing border-box
      div
        font-size 14px
        span
          font-size 14px
          color #999999
    & .oa-receive-list
      & .oa-receive
        border 1px solid #cccccc
        border-radius 5px
        padding 10px 10px
        box-sizing border-box
        margin-top 20px
        & .oa-receive-title
          display flex
          flex-direction row
          align-items center
          justify-content space-between
          font-size 10px
          color #999999
          & .oa-receive-title-btn
            flex:1
            display flex
            flex-direction row
            align-items center
            justify-content flex-end
            margin-right 20px
            & > div
              width 80px;
              height 24px
              line-height 24px
              text-align: center
              background #0079fe
              color #fff
          img
            width 20px
            height 20px
        & .oa-receive-content
          & .oa-receive-content-box
            display flex
            flex-direction row
            justify-content space-between
            align-items center
            & .oa-receive-content-box-resource
              display flex
              flex-direction row
              align-items center
              flex-wrap wrap
              & .resource-image-box
                position relative
                width 70px
                height 70px
                margin-right 10px
                margin-bottom 10px
                & .iconfont
                  font-size 70px
                & .resource-image-img
                  width 100%
                  height 100%
                  object-fit contain
                & .resource-image-delete
                  position absolute
                  width 20px
                  height 20px
                  right -10px
                  top -10px
            & .oa-receive-content-box-time
              font-size 14px
            div
              display flex
              flex-direction row
              align-items center
            & .oa-receive-content-box-price
              flex 1
              & .van-cell
                width 70%
              & .van-cell::after
                border none
    & .oa-receive-add
      width 100%
      height 30px
      display flex
      flex-direction row
      justify-content center
      align-items center
      font-size 16px
      color #0079FE
.oa-button-view
  width 100%
  background #FFFFFF
  position fixed
  bottom 0px
  right 0px
  padding 10px
  box-sizing border-box
  display flex
  flex-direction row
  justify-content center
  button
    width 80px
    height 50px
    border-radius 5px
    font-size 16px
    background #0079FE
    border none
    color #FFFFFF
</style>
