var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("van-calendar", {
        attrs: { "default-date": _vm.stageDate },
        on: { confirm: _vm.selectStage },
        model: {
          value: _vm.stageVisible,
          callback: function ($$v) {
            _vm.stageVisible = $$v
          },
          expression: "stageVisible",
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.payTypeListVisible,
            callback: function ($$v) {
              _vm.payTypeListVisible = $$v
            },
            expression: "payTypeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.payTypeList,
              radio: _vm.payTypeSelect,
              title: "支付方式",
            },
            on: { select: _vm.handlePayTypeListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.itemListVisible,
            callback: function ($$v) {
              _vm.itemListVisible = $$v
            },
            expression: "itemListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.itemList,
              radio: _vm.categorySelect,
              title: "收支类型",
            },
            on: { select: _vm.handleItemListSelect },
          }),
        ],
        1
      ),
      _c("van-number-keyboard", {
        attrs: {
          show: _vm.show,
          theme: "custom",
          "extra-key": ".",
          "close-button-text": "完成",
        },
        on: {
          blur: function ($event) {
            _vm.show = false
          },
          input: _vm.onInput,
          delete: _vm.onDelete,
          hide: _vm.hideKeyboard,
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "get-container": "body" },
          model: {
            value: _vm.payeeListVisible,
            callback: function ($$v) {
              _vm.payeeListVisible = $$v
            },
            expression: "payeeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.payeeList,
              radio: _vm.payeeSelect,
              title: "收款人",
            },
            on: { select: _vm.handlePayeeListSelect },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "oa-add-receive" }, [
        _c(
          "div",
          {
            staticClass: "oa-add-receive-case",
            on: { click: _vm.switchCaseList },
          },
          [
            _vm.caseName === ""
              ? _c("div", { staticStyle: { color: "#999" } }, [
                  _vm._v("请选择案件"),
                ])
              : _c("div", [_vm._v(_vm._s(_vm.caseName))]),
          ]
        ),
        _c("div", { staticClass: "oa-add-receive-content" }, [
          _vm.caseName !== ""
            ? _c("div", { staticClass: "oa-case-price" }, [
                _c("div", [
                  _c("span", [_vm._v("应收：")]),
                  _vm._v(_vm._s(_vm.price.receivable)),
                ]),
                _c("div", [
                  _c("span", [_vm._v("实收：")]),
                  _vm._v(_vm._s(_vm.price.received)),
                ]),
                _c("div", { staticStyle: { color: "red" } }, [
                  _c("span", [_vm._v("未收：")]),
                  _vm._v(_vm._s(_vm.price.uncollected) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.caseName !== ""
            ? _c(
                "div",
                { staticClass: "oa-receive-list" },
                _vm._l(_vm.receiveList, function (item, i) {
                  return _c("div", { key: i, staticClass: "oa-receive" }, [
                    _c("div", { staticClass: "oa-receive-title" }, [
                      _c("div", [_vm._v("收支" + _vm._s(i + 1))]),
                      _c("img", {
                        attrs: {
                          src: require("../../../../../public/img/icons/icon_delete.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteReceive(item, i)
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "oa-receive-content" }, [
                      _c("div", { staticClass: "oa-receive-content-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "oa-receive-content-box-time",
                            staticStyle: { color: "#0079FE" },
                            on: {
                              click: function ($event) {
                                return _vm.switchStage(item, i)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.stage) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              click: function ($event) {
                                return _vm.switchItemList(item, i)
                              },
                            },
                          },
                          [
                            item.typeText === ""
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#CCCCCC" } },
                                  [_vm._v("请选择收支类型")]
                                )
                              : _c("span", [_vm._v(_vm._s(item.typeText))]),
                            _c("van-icon", {
                              attrs: {
                                name: "arrow-down",
                                color: "#CCCCCC",
                                size: "14px",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "oa-receive-content-box-price" },
                          [
                            _c("van-field", {
                              attrs: {
                                placeholder: "请输入",
                                "input-align": "right",
                                type: "number",
                                readonly: "",
                                clickable: "",
                              },
                              on: {
                                touchstart: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onKeyNumber(item, i)
                                },
                                blur: function ($event) {
                                  return _vm.amountBlur(item, i)
                                },
                              },
                              model: {
                                value: item.amount,
                                callback: function ($$v) {
                                  _vm.$set(item, "amount", $$v)
                                },
                                expression: "item.amount",
                              },
                            }),
                            _c("span", [
                              _c("span", [_vm._v("元")]),
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "oa-receive-content-box" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.switchPayTypeList(item, i)
                              },
                            },
                          },
                          [
                            item.payTypeText === ""
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#CCCCCC" } },
                                  [_vm._v("支付方式")]
                                )
                              : _c("span", [_vm._v(_vm._s(item.payTypeText))]),
                            _c("van-icon", {
                              attrs: { name: "arrow-down", size: "18px" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("van-field", {
                              attrs: {
                                placeholder: "请输入支付账号",
                                "input-align": "right",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.accountBlur(item, i)
                                },
                              },
                              model: {
                                value: item.account,
                                callback: function ($$v) {
                                  _vm.$set(item, "account", $$v)
                                },
                                expression: "item.account",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "oa-receive-content-box" }, [
                        _c("div", [_vm._v("付款人")]),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px 0" } },
                          [
                            _c("van-field", {
                              attrs: {
                                placeholder: "请输入付款人",
                                "input-align": "right",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.paymenterBlur(item, i)
                                },
                              },
                              model: {
                                value: item.paymenter,
                                callback: function ($$v) {
                                  _vm.$set(item, "paymenter", $$v)
                                },
                                expression: "item.paymenter",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "oa-receive-content-box",
                          on: {
                            click: function ($event) {
                              return _vm.switchPayeeList(item, i)
                            },
                          },
                        },
                        [
                          _c("div", [_vm._v("收款人")]),
                          _c(
                            "div",
                            [
                              item.payee === "" || item.payee === null
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#CCCCCC" } },
                                    [_vm._v("请选择收款人")]
                                  )
                                : _c("span", [_vm._v(_vm._s(item.payee))]),
                              _c("van-icon", {
                                attrs: {
                                  name: "arrow-down",
                                  color: "#CCCCCC",
                                  size: "14px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "oa-receive-content-box" }, [
                        _c("div", [_vm._v("备注")]),
                        _c(
                          "div",
                          [
                            _c("van-field", {
                              attrs: {
                                placeholder: "请输入内容",
                                "input-align": "left",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.remarksBlur(item, i)
                                },
                              },
                              model: {
                                value: item.remarks,
                                callback: function ($$v) {
                                  _vm.$set(item, "remarks", $$v)
                                },
                                expression: "item.remarks",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("img", {
                          attrs: {
                            src: require("../../../../../public/img/icons/u4647-1.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addImg(item, i)
                            },
                          },
                        }),
                      ]),
                      item.collections.length > 0
                        ? _c("div", { staticClass: "oa-receive-content-box" }, [
                            _c(
                              "div",
                              {
                                staticClass: "oa-receive-content-box-resource",
                              },
                              _vm._l(item.collections, function (items, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "resource-image-box",
                                  },
                                  [
                                    items.type === "WORD"
                                      ? _c("i", {
                                          staticClass: "iconfont icon-word",
                                          staticStyle: {
                                            color: "rgb(108, 168, 255)",
                                          },
                                        })
                                      : _vm._e(),
                                    items.type === "EXCEL"
                                      ? _c("i", {
                                          staticClass: "iconfont icon-exl",
                                          staticStyle: {
                                            color: "rgb(139, 221, 108)",
                                          },
                                        })
                                      : _vm._e(),
                                    items.type === "PDF"
                                      ? _c("i", {
                                          staticClass: "iconfont icon-PDF",
                                          staticStyle: {
                                            color: "rgb(62, 179, 240)",
                                          },
                                        })
                                      : _vm._e(),
                                    items.type === "VIDEO"
                                      ? _c("i", {
                                          staticClass: "iconfont icon-shipin",
                                          staticStyle: {
                                            color: "rgb(75, 158, 251)",
                                          },
                                        })
                                      : _vm._e(),
                                    items.type === "AUDIO"
                                      ? _c("i", {
                                          staticClass: "iconfont icon-shipin",
                                          staticStyle: {
                                            color: "rgb(75, 158, 251)",
                                          },
                                        })
                                      : _vm._e(),
                                    items.type === "IMAGE"
                                      ? _c("img", {
                                          staticClass: "resource-image-img",
                                          attrs: {
                                            src: items.content,
                                            alt: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("img", {
                                      staticClass: "resource-image-delete",
                                      attrs: {
                                        src: require("../../../../../public/img/icons/icon_delete.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteImg(i, index)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.caseName !== ""
            ? _c(
                "div",
                {
                  staticClass: "oa-receive-add",
                  on: { click: _vm.addReceive },
                },
                [
                  _c("i", { staticClass: "iconfont icon-add" }),
                  _c("div", [_vm._v("添加")]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }